import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { pdf } from '@react-pdf/renderer';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Col, Divider, Dropdown, Menu, Row, Space, Tag, Tooltip, Typography, message } from 'antd';
import { colors, toRgb, toRgba } from 'common/styles/colors';
import { ConvertModal } from 'components/atoms/popups/ConvertModal';
import { LostModal } from 'components/atoms/popups/LostModal';
import { SendEmailModal } from 'components/atoms/popups/SendEmailModal';
import { WonModal } from 'components/atoms/popups/WonModal';
import { DuplicateModal } from 'components/molecules/DuplicateModal';
import { PdfGenerator } from 'components/molecules/PdfGenerator';
import { PdfGeneratorDiscount } from 'components/molecules/PdfGeneratorDiscount';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { ConvertQuotePayload, QuotePayload } from 'models/Quotes';
import { useState } from 'react';
import Lottie from 'react-lottie-player';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useConvertQuoteMutation,
  useCreatePDFMutation,
  useCreateQuoteMutation,
  useGetQuoteQuery,
  useLostQuoteMutation,
  useSendQuoteMutation,
  useUpdateQuoteMutation,
  useWonQuoteMutation
} from 'redux/services/samTheRobot/samTheRobotApi';
import { setQuoteSubmissionStatusMessage } from 'redux/slices/quoteSlice';
import { useAppSelector } from 'redux/store';
import duplicateLottie from '../../common/assets/dupe-lottie.json';
import loseLottie from '../../common/assets/lose-lottie.json';
import sendLottie from '../../common/assets/send-to-customer-lottie.json';
import winLottie from '../../common/assets/win-lottie.json';

const styles: InlineStylesModel = {
  container: {
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  draftButton: {
    color: toRgb(colors.raisinBlackLight),
    backgroundColor: '#EED202',
    border: 'none'
  },
  requestButton: {
    color: toRgb(colors.culturedWhite),
    backgroundColor: toRgb(colors.illuminatingEmerald),
    border: 'none'
  },
  headerTitle: {
    fontWeight: 'bold',
    color: '#2F5596'
  },
  statusTag: {
    paddingLeft: 10,
    paddingRight: 10,
    border: '1px solid black',
    borderRadius: 10
  }
};

export const ActionControls = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [hover, setHover] = useState<string | null>();
  const [isSendEmailOpen, setIsSendEmailOpen] = useState(false);
  const [isWonOpen, setIsWonOpen] = useState(false);
  const [isLostOpen, setIsLostOpen] = useState(false);
  const [isConvertOpen, setIsConvertOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateOpen] = useState(false);

  const { submitForm, values, setFieldValue, isSubmitting } = useFormikContext<QuotePayload>();

  const { selectedCompanyDataAreaId } = useAppSelector((state) => state.orders);
  const { selectedLegalEntity } = useAppSelector((state) => state.app);

  const [createPdf] = useCreatePDFMutation();
  const [sendQuote] = useSendQuoteMutation();
  const [wonQuote] = useWonQuoteMutation();
  const [lostQuote] = useLostQuoteMutation();
  const [updateQuote, { isLoading: quoteUpdating }] = useUpdateQuoteMutation();
  const [createQuote, { isLoading: quoteSubmitting }] = useCreateQuoteMutation();
  const [convertQuote, { isLoading: convertingQuote }] = useConvertQuoteMutation();

  const { data, isLoading, isFetching } = useGetQuoteQuery(id ?? skipToken);

  const isCreate = pathname.includes('create');
  const isEdit = pathname.includes('edit');
  const isView = pathname.includes('view');
  const isDuplicates = pathname.includes('duplicate');
  const isWon = data?.status === 'Won';
  const isLost = data?.status === 'Lost';
  const isConverted = data?.convertedToSalesOrder;

  const handleDuplicate = async (): Promise<void> => {
    setIsDuplicateOpen(true);
  };

  const handleSendButton = (): void => {
    dispatch(setQuoteSubmissionStatusMessage('Sending Sales Quote...'));
    if (!values.customerEmailAddresses) message.error('There are no customer emails to send the quote to');
    else {
      setIsSendEmailOpen(true);
    }
  };

  const handleSendFromNew = async (pdfVersion: string): Promise<void> => {
    const pdfBlob = await pdf(
      pdfVersion === 'discount' ? (
        <PdfGeneratorDiscount legalEntity={selectedLegalEntity} products={values.lines} pdfData={values} />
      ) : (
        <PdfGenerator legalEntity={selectedLegalEntity} products={values.lines} pdfData={values} />
      )
    ).toBlob();
    const formData = new FormData();

    if (!pdfBlob) throw new Error('No PDF file found');
    formData.append('file', pdfBlob, 'quoteIdTest');
    if (!selectedCompanyDataAreaId) return message.error('Legal entity not selected, please refresh the page and try again');

    try {
      const totalPrice = values.lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.extendedPrice || 0);
      }, 0);
      const newQuoteData = await createQuote({
        ...values,
        dataAreaId: selectedCompanyDataAreaId,
        customerErpId: values.customerAccount,
        lines: values.lines,
        totalPrice
      }).unwrap();

      await createPdf({ quoteId: newQuoteData.id, formData }).unwrap();
      await sendQuote(newQuoteData.id).unwrap();
      message.success('Succesfully Sent Quote!');
      nav('/search');
    } catch (err: any) {
      console.log(err);
      if (err.originalStatus === 204) {
        message.success('Succesfully Sent Quote');
        nav('/search');
      } else {
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  };

  const handleSendFromDraft = async (pdfVersion: string): Promise<void> => {
    const pdfBlob = await pdf(
      pdfVersion === 'discount' ? (
        <PdfGeneratorDiscount legalEntity={selectedLegalEntity} products={values.lines} pdfData={values} />
      ) : (
        <PdfGenerator legalEntity={selectedLegalEntity} products={values.lines} pdfData={values} />
      )
    ).toBlob();
    const formData = new FormData();

    if (!pdfBlob) throw new Error('No PDF file found');
    if (!id) throw new Error('Can not send Quote..');

    formData.append('file', pdfBlob, 'quoteIdTest');

    try {
      await submitForm();

      await createPdf({ quoteId: id, formData }).unwrap();
      await sendQuote(id).unwrap();
    } catch (err) {
      console.log((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  const handleLost = async (): Promise<void> => {
    if (id) {
      try {
        const lostQuoteResponse = await lostQuote(id).unwrap();

        if (lostQuoteResponse) {
          message.success('Quote succesfully Lost!');
          nav('/search');
        }
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  };

  const handleWon = async (): Promise<void> => {
    // const pdfBlob = await pdf(<PdfGenerator legalEntity={selectedLegalEntity} products={values.lines} pdfData={values} />).toBlob();
    // const formData = new FormData();

    // if (!pdfBlob) throw new Error('No PDF file found');
    // formData.append('file', pdfBlob, 'quoteIdTest');

    if (!selectedCompanyDataAreaId) return message.error('No legal entity found, please refresh the page and try again');

    if (id) {
      try {
        const totalPrice = values.lines.reduce((accumulator, quoteLineObject) => {
          return accumulator + Number(quoteLineObject.extendedPrice || 0);
        }, 0);
        const updateResponse = await updateQuote({
          ...values,
          dataAreaId: selectedCompanyDataAreaId,
          customerErpId: values.customerAccount,
          lines: values.lines,
          totalPrice
        }).unwrap();
        // const pdfResponse = await createPdf({ quoteId: id, formData }).unwrap();
        const wonQuoteResponse = await wonQuote(id).unwrap();

        if (updateResponse && wonQuoteResponse) {
          message.success('Quote succesfully Won!');
          nav(`/view/${id}`);
        }
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  };

  const handleConvertQuote = async ({ quoteId, ownerId, dispositionId, siteId, warehouseId }: ConvertQuotePayload): Promise<void> => {
    if (id) {
      try {
        const convertedResponse = await convertQuote({ quoteId: id, ownerId, dispositionId, siteId, warehouseId }).unwrap();

        if (convertedResponse) {
          message.success('Quote succesfully Converted Into Sales!');
          window.open(`${process.env.REACT_APP_SALES_ORDERS_BASE_URL}drafts/${convertedResponse.productSalesOrder.id}`);
        }
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage, 5);
      }
    }
  };

  const handleConvertToDraft = async (): Promise<void> => {
    try {
      dispatch(setQuoteSubmissionStatusMessage('Saving sales quote'));
      setFieldValue('closeWizard', false);
      await submitForm();
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  const menuJSX = (
    <Menu disabled={!values.customerName || !values.quoteName}>
      <Menu.Item
        onClick={() => {
          dispatch(setQuoteSubmissionStatusMessage('Saving Sales Quote...'));
          setFieldValue('closeWizard', false);
          submitForm();
        }}
        key="1">
        Save
      </Menu.Item>
    </Menu>
  );

  /* ******************** Render ******************** */
  return (
    <Space size="large" style={styles.container}>
      {/**** *** *** *** *** ACTION POPUPS *** *** *** *** ****/}
      <SendEmailModal sendQuoteEmail={id ? handleSendFromDraft : handleSendFromNew} closeModal={(): void => setIsSendEmailOpen(false)} isModalOpen={isSendEmailOpen} />
      <WonModal awardQuote={handleWon} closeModal={(): void => setIsWonOpen(false)} isModalOpen={isWonOpen} />
      <LostModal loseQuote={handleLost} closeModal={(): void => setIsLostOpen(false)} isModalOpen={isLostOpen} />
      <ConvertModal convertQuote={handleConvertQuote} closeModal={(): void => setIsConvertOpen(false)} isModalOpen={isConvertOpen} isConvertingQuote={convertingQuote} />
      {/**** *** *** *** *** *** *** *** *** *** *** *** *** ****/}
      <Row align="middle">
        <Space>
          {isEdit && !isLoading ? (
            <Row>
              <Typography.Text style={{ fontSize: 20 }}>Edit Sales Quote</Typography.Text>
            </Row>
          ) : (
            <Typography.Text style={{ fontSize: 20 }}>{isDuplicates ? 'Duplicate Sales Quote' : isView ? 'View Sales Quote' : 'Create Sales Quote'}</Typography.Text>
          )}
        </Space>
        <Space style={{ marginLeft: 10 }}>
          <Tooltip title="Status">
            {isCreate ? (
              <Tag color={toRgba(colors.royalBlueLight, 0.8)}>New</Tag>
            ) : isDuplicates ? (
              <Tag color={toRgba(colors.romanSilver, 0.8)}>Duplicate</Tag>
            ) : isEdit || isView ? (
              data?.convertedToSalesOrder ? (
                <Tag color={toRgba(colors.illuminatingEmerald, 0.8)}>Converted To Sales Order</Tag>
              ) : data?.status === 'Draft' ? (
                <Tag color={toRgba(colors.orangeWeb, 0.8)}>Draft</Tag>
              ) : data?.status === 'SentToCustomer' ? (
                <Tag color={toRgba(colors.robinEggBlue, 0.8)}>Sent</Tag>
              ) : isWon ? (
                <Tag color={toRgba(colors.illuminatingEmerald, 0.8)}>Won</Tag>
              ) : isLost ? (
                <Tag color={toRgba(colors.redSalsa, 0.8)}>Lost</Tag>
              ) : null
            ) : null}
          </Tooltip>
        </Space>
      </Row>
      <Space size="small">
        <Row gutter={[10, 10]} justify="end">
          {id && (
            <>
              {/* <Col>
                <Button onClick={handleGoBack}>
                  <ArrowLeftOutlined /> Back
                </Button>
              </Col> */}
              <Col>
                <DuplicateModal isDuplicateModalOpen={isDuplicateModalOpen} setIsDuplicateOpen={setIsDuplicateOpen} />
                <Tooltip title="Duplicate" placement="bottom">
                  <Button
                    className="quote-card__menu-item-duplicate"
                    onMouseEnter={(): void => setHover('duplicate')}
                    onMouseLeave={(): void => setHover(null)}
                    style={{ borderRadius: '50%', borderColor: 'rgba(113, 113, 113, 0.6)' }}
                    icon={<Lottie animationData={duplicateLottie} loop={false} goTo={!hover ? 0 : undefined} play={hover === 'duplicate'} style={{ width: 25, height: 25, display: 'inline-block' }} />}
                    loading={isLoading}
                    onClick={handleDuplicate}
                  />
                </Tooltip>
              </Col>
              {(isWon || isLost) && !data.convertedToSalesOrder && (
                <Col>
                  <Tooltip title="Convert back to draft" placement="bottom">
                    <Button
                      className="quote-card__menu-item-duplicate"
                      onMouseEnter={(): void => setHover('duplicate')}
                      onMouseLeave={(): void => setHover(null)}
                      style={{ borderRadius: '50%', borderColor: 'rgba(113, 113, 113, 0.6)' }}
                      icon={<EditOutlined style={{ width: 25, height: 25, display: 'inline-block' }} />}
                      loading={isSubmitting}
                      onClick={handleConvertToDraft}
                    />
                  </Tooltip>
                </Col>
              )}
              {!isWon && !isLost ? (
                <>
                  <Col>
                    <Tooltip title="Won" placement="bottom">
                      <Button
                        className="quote-card__menu-item-award"
                        onMouseEnter={(): void => setHover('award')}
                        onMouseLeave={(): void => setHover(null)}
                        style={{ borderRadius: '50%', borderColor: '#4e937ac9' }}
                        icon={<Lottie animationData={winLottie} loop={false} goTo={!hover ? 0 : undefined} play={hover === 'award'} style={{ width: 25, height: 25, display: 'inline-block' }} />}
                        type="default"
                        loading={isLoading || isFetching}
                        disabled={!values.customerName || !values.quoteName}
                        onClick={(): void => setIsWonOpen(true)}
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title="Lost" placement="bottom">
                      <Button
                        className="quote-card__menu-item-delete"
                        style={{ borderRadius: '50%', borderColor: '#dc3545' }}
                        icon={
                          <Lottie
                            onMouseEnter={(): void => setHover('delete')}
                            onMouseLeave={(): void => setHover(null)}
                            animationData={loseLottie}
                            loop={false}
                            goTo={!hover ? 0 : undefined}
                            play={hover === 'delete'}
                            style={{ width: 25, height: 25, display: 'inline-block' }}
                          />
                        }
                        type="default"
                        loading={isLoading || isFetching}
                        disabled={!values.customerName || !values.quoteName}
                        onClick={(): void => setIsLostOpen(true)}
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title="Send" placement="bottom">
                      <Button
                        style={{ borderRadius: '50%', borderColor: '#2786fabf' }}
                        onMouseEnter={(): void => setHover('sendToCustomer')}
                        onMouseLeave={(): void => setHover(null)}
                        className="quote-card__menu-item-send"
                        icon={
                          <Lottie
                            animationData={sendLottie}
                            loop={false}
                            goTo={!hover ? 0 : undefined}
                            play={hover === 'sendToCustomer'}
                            style={{ width: 25, height: 25, display: 'inline-block', transform: 'scale(0.9)' }}
                          />
                        }
                        type="default"
                        loading={isLoading || isFetching}
                        disabled={!values.customerName || !values.quoteName}
                        onClick={handleSendButton}
                      />
                    </Tooltip>
                  </Col>
                  <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <Divider type="vertical" style={{ height: '60%' }} />
                  </Col>
                  {/* <Col>
                    <Button onClick={handleDelete} type="ghost" danger>
                      Delete
                    </Button>
                  </Col> */}
                </>
              ) : null}
            </>
          )}
          <Col>
            <Button
              onClick={(): void => {
                nav('/search');
              }}>
              <ArrowLeftOutlined /> Back
            </Button>
          </Col>

          {isWon || isLost ? (
            <Col>
              <Button type="default" loading={isLoading || isFetching} disabled={isLost || isConverted} onClick={() => setIsConvertOpen(true)}>
                Convert To Sales
              </Button>
            </Col>
          ) : (
            <>
              <Col>
                <Dropdown.Button
                  disabled={!values.customerName || !values.quoteName}
                  type="primary"
                  onClick={(): void => {
                    dispatch(setQuoteSubmissionStatusMessage('Saving Sales Quote...'));
                    setFieldValue('closeWizard', true);
                    submitForm();
                  }}
                  overlay={menuJSX}>
                  Save &amp; Close
                </Dropdown.Button>
              </Col>
            </>
          )}
          {isCreate && (
            <Col>
              <Button type="default" loading={isLoading || isFetching} disabled={!values.customerName || !values.quoteName} onClick={handleSendButton}>
                Send Quote
              </Button>
            </Col>
          )}
        </Row>
      </Space>
    </Space>
  );
};
